import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/analytics';

// Your web app's Firebase configuration
var firebaseConfig = {
    apiKey: "AIzaSyAASwZg2eIqeN8yuRbMD-haUhkgzwO5Nbs",
    authDomain: "pipette-top.firebaseapp.com",
    databaseURL: "https://pipette-top.firebaseio.com",
    projectId: "pipette-top",
    storageBucket: "pipette-top.appspot.com",
    messagingSenderId: "825026281525",
    appId: "1:825026281525:web:0ec2410c6263b11293f17c",
    measurementId: "G-9LPM790JF2"
  };
  // Initialize Firebase
  firebase.initializeApp(firebaseConfig);
  firebase.analytics();

  export default firebase;