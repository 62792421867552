import React from 'react'
import styles from './Bio.module.css'
// import { getGravatarURL } from '../utils/getGravatarURL'

function Bio(props) {
  // let photoURL = getGravatarURL({
  //   email: "test2@example.com",
  //   size: 56,
  // })

  // let qphotoURL = 'https://cdn-images-1.medium.com/fit/c/100/100/2*PFzDbAcWRxneG9GrgDYtPg.png';

  return (
    <div className={`
      ${styles.Bio}
      ${props.className || ''}
    `}>
      {/* <img src={qphotoURL} alt="I" /> */}
      {/* <i className="fas fa-flask" /> */}
      <span className={styles.Pipette}>
      <i className="fas fa-eye-dropper"></i>
      </span>
      <p>
        A platform to share ideas, observations in measured quantity and at periodic interval. 
      </p>
    </div>
  )
}

export default Bio
