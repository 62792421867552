export default {
  // The blog's title as it appears in the layout header, and in the document
  // <title> tag.
  title: "Pipette top",

  author: "Vinay",
  description: "Observations in measured quantity and periodic interval",

  // The number of posts to a page on the site index.
  indexPageSize: 8,
}
